import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Position component is a wrapper component that gives the containing component css positioning abilities.`}</p>
    <h2>{`Default examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Box p={2} mb={200}>
  <Heading mb={2}>Relative + Absolute</Heading>
  <Relative size={128} mx={128} my={6}>
    <Box border={1} borderColor="gray.2" size="100%">
      <Absolute left="100%" top={0} bg="red.1" p={1}>rt</Absolute>
      <Absolute right="100%" top={0} bg="green.1" p={1}>lt</Absolute>
      <Absolute left="100%" bottom={0} bg="blue.1" p={1}>rb</Absolute>
      <Absolute right="100%" bottom={0} bg="turquoise.1" p={1}>lb</Absolute>
      <Absolute left={0} top="100%" bg="orange.1" p={1}>bl</Absolute>
      <Absolute right={0} top="100%" bg="yellow.3" p={1}>br</Absolute>
      <Absolute left={0} bottom="100%" bg="red.1" p={1}>tl</Absolute>
      <Absolute right={0} bottom="100%" bg="blue.1" p={1}>tr</Absolute>
    </Box>
  </Relative>

  <Heading my={2}>Sticky</Heading>

  <BorderBox border={1} borderColor="green.5" height={500}>
    <Sticky top={0} bg="green.2" p={6}>
      I'm sticky!
    </Sticky>
  </BorderBox>

  <Heading my={2}>Fixed</Heading>
  <p>(see the bottom right of the screen)</p>

  <Fixed bottom={0} right={0} bg="red.2" p={2}>
    I'm fixed to the bottom right.
  </Fixed>
</Box>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Position components get `}<inlineCode parentName="p">{`POSITION`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{`, and `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`Position does not get any additional props other than the system props mentioned above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      